import React, { useRef } from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
    Text,
    View,
    Dimensions,
    Modal,
    Animated,
    TouchableOpacity,
    ScrollView,
    Platform
} from 'react-native';

import SVGView from "../../utils/SvgView";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import Code from "../Code/CodeSheet";
import SelectInstitutionType from "../Authentication/Forms/SelectInstituionType";
import AudioChapter from "../Audio/AudioChapter";

interface ActionSheetProps {
    onChange?: (data?: any) => void,
    componentId: string,
    textColor?: string,
    backgroundColor?: string
}

export type ActionSheetType = "READ_MORE" | "CODE" | "INSTITUTION_TYPE" | "AUDIO_CHAPTER" | "";

interface ActionSheetData {
    arrayOfStrings?: string[],
    title?: string,
    urls?: string[],
    chapters?: string[],
    localImage?: string,
    remoteImage?: string,
    color?: string,
    navColor?: string,
    activityId?: string
}

interface ActionSheetContent {
    isVisible: boolean,
    actionSheetType: ActionSheetType,
    data?: ActionSheetData
}

const ActionSheet = forwardRef((props: ActionSheetProps, ref: any) => {

    const appTheme: AppTheme = new AppTheme();

    const { onChange, componentId, textColor, backgroundColor = appTheme.white } = props;

    const bottomSheetMarginBottom = useRef(new Animated.Value(-appTheme.getFullAppHeight())).current;

    const hideTheAlert = () => {
        Animated.timing(bottomSheetMarginBottom, {
            toValue: -appTheme.getFullAppHeight(),
            duration: 500,
            useNativeDriver: false
        }).start(() => {
            setTimeout(() => {
                setAlertContent({
                    isVisible: false,
                    actionSheetType: ""
                });
            }, 100);
        });
    }

    const [alertContent, setAlertContent] = useState<ActionSheetContent>({
        isVisible: false,
        actionSheetType: ""
    });

    // ComponentDidMount
    useEffect(() => {
        if (alertContent.isVisible === true) {
            Animated.timing(bottomSheetMarginBottom, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start();
        }
    }, [alertContent.isVisible]);

    useImperativeHandle(ref, () => ({

        showAlert(alert: { actionSheetType: ActionSheetType, data: ActionSheetData }) {
            const { actionSheetType, data } = alert;
            setAlertContent({
                actionSheetType,
                isVisible: true,
                data
            });
        },

        hideAlert() {
            hideTheAlert();
        }
    }));

    const getActionSheetComponent = () => {
        if (alertContent.actionSheetType === "READ_MORE") {
            const textsToDisplay: string[] | undefined = alertContent.data?.arrayOfStrings;
            if (textsToDisplay !== undefined) {
                let paragraphs = [];
                for (const aTextToDisplayIndex in textsToDisplay) {
                    if (Object.prototype.hasOwnProperty.call(textsToDisplay, aTextToDisplayIndex)) {
                        const aText = textsToDisplay[aTextToDisplayIndex];
                        if (paragraphs.length > 0) {
                            paragraphs.push(<Divider key={"divider_" + aTextToDisplayIndex} />);
                        }
                        paragraphs.push(<Text key={"parag_" + aTextToDisplayIndex} style={{ color: textColor, fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "left" }}>{aText}</Text>)
                    }
                }
                return <View>
                    <View style={[{ width: "100%", paddingVertical:appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center" }]}>
                        <Text style={[{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.superHerosFontBold, fontSize: appTheme.pixelPerfectForFont(10), color: textColor, textAlign:"center" }]} >{alertContent.data?.title.toLocaleUpperCase()}</Text>
                    </View>
                    <ScrollView>
                        {paragraphs}
                    </ScrollView>
                </View>
            }
        } else if (alertContent.actionSheetType === "CODE") {
            return <Code />
        } else if (alertContent.actionSheetType === "INSTITUTION_TYPE") {
            return <SelectInstitutionType onSelectionDone={onSelectionDone} />
        } else if (alertContent.actionSheetType === "AUDIO_CHAPTER") {
            return <AudioChapter activityId={alertContent.data?.activityId} navColor={alertContent.data?.navColor} color={alertContent.data?.color} title={alertContent.data?.title} localImage={alertContent.data?.localImage} remoteUrl={alertContent.data?.remoteImage} urls={alertContent.data?.urls} chapters={alertContent.data?.chapters} onSelectionDone={onSelectionDone} />
        }
        return <View />
    }
    
    const onSelectionDone = (data:any) => {
        if (onChange !== undefined) {
            onChange(data);
        }
        hideTheAlert();
    }

    const actionSheetWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();

    return (
        <Modal
            transparent={true}
            animationType="fade"
            visible={alertContent.isVisible}
            style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }}>
            <View style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height, justifyContent: "flex-end", alignItems: "center", backgroundColor: appTheme.alertOverlayColor }}>
                <Animated.View style={{ marginBottom: bottomSheetMarginBottom, minHeight: appTheme.pixelPerfect(60), maxHeight: Dimensions.get("window").height - appTheme.pixelPerfect(20) - appTheme.topInsets, width: actionSheetWidth, paddingBottom: appTheme.pixelPerfect(40)+appTheme.bottomInsets, borderRadius: appTheme.pixelPerfect(10), backgroundColor }}>
                    {getActionSheetComponent()}
                    <TouchableOpacity onPress={hideTheAlert} style={{ position: "absolute", top: appTheme.pixelPerfect(10), right: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius:appTheme.pixelPerfect(15), backgroundColor:textColor, justifyContent: "center", alignItems: "center" }}>
                        <SVGView Component={IcClose} size={appTheme.pixelPerfect(24)} color={backgroundColor} />
                    </TouchableOpacity>
                </Animated.View>
            </View>
        </Modal>
    )
});

export default ActionSheet;