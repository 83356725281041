import React, { useEffect, useState } from "react";

import {
    View,
    Text,
    Dimensions,
    Platform,
    Image,
    TouchableOpacity
} from "react-native";
import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { isConnnected, signOut } from "../../services/Auth";
import LHQAuth from "./LHQAuth";
import LHQAdminDashboard from "./LHQAdminDashboard";
import LHQTeamLeaderDashboard from "./LHQTeamLeaderDashboard";
import LHQCreateCode from "./LHQCreateCode";
import LHQDocumentation from "./LHQDocumentation";
import LHQCategoriesManagement from "./LHQCategoriesManagement"
import User from "../../data/user/User";
import { getUserWithId } from "../../services/Database";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { default as IcLogOut } from "../../assets/svg/lili/ic_logout.svg";
import { logoutUser } from "../Splashcreen/Splashscreen";
import { getSessionsCategories } from "../../../specific/utils/LocalStorage";
import { activitiesListener, categoriesListener, premiumCardListener } from "../../../specific/services/Specific_Database";

import LHQActivitiesManagement from "./LHQActivitiesManagement";

type AuthData = {
    email: string | null | undefined,
    uid: string | undefined
}

interface LHQHomeProps {

}

const LHQHome = (props: LHQHomeProps) => {

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [authData, setAuthData] = useState<AuthData | null>(null);
    const [isUserConnected, setIsUserConnected] = useState<boolean>(false);

    const [displayPage, setDisplayPage] = useState<string>("DASHBOARD")

    const onViewDidAppear = () => {
        checkUserConnexion();
    }

    useEffect(() => {
        if (authData !== null) {
            if ((authData.email.length > 0) && (authData.uid.length > 0)) {
                checkUserData();
            } else {
                setIsUserConnected(false);
                setViewDidAppear(true);
            }
        }
    }, [authData]);

    useEffect(() => {
        if (isUserConnected === true) {
            // On va récupérer les données de firebase et les stocker en sessions
            console.log("isUserCOnnected");
            getData();
        }
    }, [isUserConnected]);

    const getData = async () => {
        await categoriesListener();
        await activitiesListener();
        await premiumCardListener();
        setViewDidAppear(true);
    }

    const checkUserConnexion = async () => {
        const userAuthData: AuthData | null = await isConnnected();
        if ((userAuthData !== null) && (userAuthData.uid !== undefined)) {
            setAuthData(userAuthData);
        } else {
            setAuthData({ email: "", uid: "" });
        }
    }

    const checkUserData = async () => {
        const offlineUserData = await user.initUserData(authData);
        if (offlineUserData === undefined) {
            onLogoutRequest();
        } else {
            const dbUSerData = await getUserData();
            if (dbUSerData === undefined) {
                onLogoutRequest();
            } else {
                setIsUserConnected(true);
            }
        }
    }

    const getUserData = async () => {
        try {
            // On récupère les informations du profil utilisateur
            const userData = await getUserWithId({ user_id: authData?.uid });
            if (userData === undefined) {
                return undefined;
            }
            await user.setDBUserData(userData);
            return userData;
        } catch (error) {
            return undefined;
        }
    }

    const onLogoutRequest = async () => {
        await signOut();
        checkUserConnexion();
    }

    const navBar = () => {
        if (isUserConnected === false) {
            return <View />;
        }
        const position = Platform.OS === "web" ? "fixed" : "absolute";
        const logoutButton = isUserConnected === true ? <SVGIconButton onPress={onLogoutRequest} icon={IcLogOut} color={appTheme.talk} buttonSize={appTheme.pixelPerfect(30)} /> : <View />;
        return <View style={[Style.navBarShadowed, { paddingHorizontal: appTheme.pixelPerfect(10), width: appTheme.getFullAppWidth(), height: appTheme.pixelPerfect(80), backgroundColor: appTheme.grow, position, flexDirection: "row", alignItems: "center" }]}>
            <Image source={require("../../assets/img/logo_central_perk_sd.png")} style={{ width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(80) }} />
            <Text style={{ color: appTheme.white, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(12) }}>
                LILI HQ
            </Text>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
                {logoutButton}
            </View>
        </View>
    }

    // const body = () => {
    //     if (viewDidAppear === false) {
    //         return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
    //             <ActivityIndicator loading={true} color={appTheme.talk} />
    //         </View>
    //     }
    //     if (isUserConnected === false) {
    //         return <LHQAuth onConnexionSucceed={checkUserConnexion} />
    //     }
    //     if (user.admin === true) {
    //         return <LHQAdminDashboard />
    //     }
    //     if (user.teamLeader === true) {
    //         return <LHQTeamLeaderDashboard />
    //     }
    //     return <Text>Bienvenue au Central Perk</Text>
    // }

    interface liliHQVerticalNavBarDataInterface {
        title: string,
        image: string,
        page: string,

    }

    const liliHQVerticalNavBarData: liliHQVerticalNavBarDataInterface[] = [
        {
            title: "Admin dashboard",
            image: "../../assets/img/chandler.jpg",
            page: "DASHBOARD"
        },
        {
            title: "Gestion des utilisateurs",
            image: "../../assets/img/chandler.jpg",
            page: "USERS"
        },
        {
            title: "Gestion des catégories",
            image: "../../assets/img/chandler.jpg",
            page: "CATEGORIES"
        },
        {
            title: "Gestion des activités",
            image: "../../assets/img/chandler.jpg",
            page: "ACTIVITIES"
        },
        {
            title: "Gestion des cartes premium",
            image: "../../assets/img/chandler.jpg",
            page: "CODES"
        },
        {
            title: "Un peu de documentation",
            image: "../../assets/img/chandler.jpg",
            page: "DOCUMENTATION"
        },
    ]

    const verticalBavBarCommandes = liliHQVerticalNavBarData.map((commande, i) => {
        return (
            <TouchableOpacity key={i} onPress={() => setDisplayPage(commande.page)} style={{ display: "flex", flexDirection: "row", margin: appTheme.pixelPerfect(20) }}>
                {/* <Image source={require("../../assets/img/chandler.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" /> */}
                <Text>
                    {commande.title}
                </Text>
            </TouchableOpacity>
        )
    })

    const body = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.talk} />
            </View>
        }

        if (isUserConnected === false) {
            return <LHQAuth onConnexionSucceed={checkUserConnexion} />
        }

        if (user.admin === true) {
            return (
                <div style={{ display: "flex" }}>
                    <div style={{ width: appTheme.getFullAppWidth() * 0.2, height: appTheme.getFullAppHeight(), backgroundColor: appTheme.daySkyColor, paddingTop: appTheme.pixelPerfect(20) }}>
                        {verticalBavBarCommandes}
                    </div>
                    {displayPage === "USERS" ? <div style={{ width: appTheme.getFullAppWidth() * 0.8, height: appTheme.getFullAppHeight(), paddingTop: appTheme.pixelPerfect(20) }}>
                        <LHQActivitiesManagement goBack={() => setDisplayPage("DASHBOARD")} />
                    </div> : displayPage !== "" && displayPage === "CATEGORIES" ? <div style={{ width: appTheme.getFullAppWidth() * 0.8, height: appTheme.getFullAppHeight(), paddingTop: appTheme.pixelPerfect(20) }}>
                        <LHQCategoriesManagement goBack={() => setDisplayPage("DASHBOARD")}/>
                    </div> : displayPage === "ACTIVITIES" ? <div style={{ width: appTheme.getFullAppWidth() * 0.8, height: appTheme.getFullAppHeight(), paddingTop: appTheme.pixelPerfect(20) }}>
                        <LHQActivitiesManagement goBack={() => setDisplayPage("DASHBOARD")} />
                    </div> : displayPage === "CODES" ? <div style={{ width: appTheme.getFullAppWidth() * 0.8, height: appTheme.getFullAppHeight(), paddingTop: appTheme.pixelPerfect(20) }}>
                        <LHQCreateCode goBack={() => setPageToDisplay("DASHBOARD")} />
                    </div> : displayPage === "DOCUMENTATION" ? <div style={{ width: appTheme.getFullAppWidth() * 0.8, height: appTheme.getFullAppHeight(), paddingTop: appTheme.pixelPerfect(20) }}>
                        <LHQDocumentation goBack={() => setPageToDisplay("DASHBOARD")} />
                    </div> : displayPage === "DASHBOARD" ? <div style={{ width: appTheme.getFullAppWidth() * 0.8, height: appTheme.getFullAppHeight(), paddingTop: appTheme.pixelPerfect(20) }}>
                        <LHQAdminDashboard />
                    </div> : <LHQAdminDashboard />}
                </div>

            )
        };

        if (user.teamLeader === true) {
            return <LHQTeamLeaderDashboard />
        }
        return <Text>Bienvenue au Central Perk</Text>
    }

    // return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight() }}>
    //     <View style={{ flex: 1, marginTop: isUserConnected === true ? appTheme.pixelPerfect(80) : 0 }}>
    //         {body()}
    //     </View>
    //     {navBar()}
    // </View>





    return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight() }}>
        <View style={{ flex: 1, flexDirection: "row", marginTop: isUserConnected === true ? appTheme.pixelPerfect(80) : 0 }}>
            {body()}
        </View>
        {navBar()}
    </View>
}

export default LHQHome;