

export const resizeFile = (file, width, height) => {

}

// on part du principe que toutes les images des activités doivent être au format 1x = 320 x 270
export const updloadActivityImage = async (data: { imageToUpload: any, categoryKey: string, activityKey: string }) => {
    
}

export const updloadMediaAudio = async (data: { audioToUpload: any, categoryKey: string, activityKey: string, mediaKey: string, index: number, language:string }) => {
    
}

export const updloadActivityAudio = async (data: { audioToUpload: any, categoryKey: string, activityKey: string, index: number, language:string }) => {
    
}