import React, { useState, useEffect } from "react";
import { View, Text, TextStyle, TouchableOpacity, Input } from "react-native";
import AppTheme from "../../utils/Theme";

import Select from "react-select"

import Button from "../../designSystem/Button";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import Divider from "../../designSystem/Divider/Divider";

import { setDBCategory } from "../../../specific/services/Specific_Database";
import { getSessionsActivities, getSessionsCategories } from "../../../specific/utils/LocalStorage";

import Category from "../../models/Category";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
interface Props {
  goBack?: () => void
}


const LHQCategoriesManagement = ({ goBack }: Props) => {

  //const { goBack } = props;

  const [categories, setCategories] = useState<any>(undefined);
  const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

  const [newCategory, setNewCategory] = useState<boolean>(false);
  const [showNewCategoryForm, setShowNewCategoryForm] = useState<boolean>(false)


  const [section, setSection] = useState<{ value: string, name: string } | undefined>(undefined);
  const [sectionId, setSectionId] = useState<string>("");

  const [analyticsKey, setAnalyticsKey] = useState<{ value: string, name: string } | undefined>(undefined);
  const [analytics_Key, setAnalytics_Key] = useState<string>("");

  const [categoryDescLang, setCategoryDescLang] = useState<{ value: string, name: string } | undefined>(undefined);
  const [categoryDesc, setCategoryDesc] = useState<string>("");

  const [categoryTitleLang, setCategoryTitleLang] = useState<string>();
  const [categoryTitle, setCategoryTitle] = useState<string>("");

  const [categoryKey, setCategoryKey] = useState<string>("");

  const [categoryOrder, setCategoryOrder] = useState<number>(-1);

  const [categoryStatus, setCategoryStatus] = useState<{ value: string, name: string } | undefined>(undefined)
  const [status, setStatus] = useState<string>("");

  const [minBuildNumber, setMinBuildNumber] = useState<number>(-1);
  const [minBuildNumberError, setMinBuildNumberError] = useState<string>("");

  const [btnClr, setBtnClr] = useState<string>("");
  const [headerBgClr, setHeaderBgClr] = useState<string>("");
  const [navClr, setNavClr] = useState<string>("");
  const [txtClr, setTxtClr] = useState<string>("");

  const [category, setCategory] = useState<Category | undefined>(undefined)


  const appTheme: AppTheme = new AppTheme();

  const h1Style: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(18)
  }

  const h2Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(16)
  }

  const h3Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(14)
  }

  const labelStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  }

  const mediaTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  }

  const attributeTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(12),
    textDecorationLine: "underline"
  }

  const inputStyle: TextStyle = {
    height: appTheme.pixelPerfect(40),
    borderRadius: appTheme.pixelPerfect(10),
    borderWidth: 1,
    borderColor: appTheme.grow,
    padding: appTheme.pixelPerfect(4),
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(6)
  }

  const pStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14)
  }

  const aStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14),
    textDecorationLine: "underline"
  }

  const onViewWillAppear = () => {
    getCategories();
  }

  const getCategories = async () => {
    const sessionCategories = await getSessionsCategories();
    console.log("***** CATEGORIES", sessionCategories)
    setCategories(sessionCategories);
  }

  // PROVISOIRE JUSTE POUR SetViewDidAppear(true) 
  useEffect(() => {
    if (section === undefined) {
      setViewDidAppear(true)
    }
  }, [section])

  // console.log("SECTION", section)


  // ********** NEW CATEGORY TO ADD TO THE DATA BASE **********
  useEffect(() => {
    console.log("NEW CATEGORY", newCategory)
    if (newCategory === true) {
      if (analytics_Key !== "" && btnClr !== "" && headerBgClr !== "" && navClr !== "" && txtClr !== "" && categoryKey !== "" && minBuildNumber >= 0 && categoryOrder >= 0 && sectionId !== "" && status !== "" && categoryTitle !== "") {
        let categoryToAdd: Category = {
          analytics_key: analytics_Key,
          body: {
            fr: categoryDesc
          },
          clr: {
            btn: btnClr,
            header_bg: headerBgClr,
            nav: navClr,
            txt: txtClr
          },
          key: categoryKey,
          last_update: new Date().getTime(),
          minBuildNumber: minBuildNumber,
          ord: categoryOrder,
          sectionId: sectionId,
          status: status,
          title: {
            fr: categoryTitle
          }
        }
        setCategory(categoryToAdd)
      }

    }
  }, [newCategory])

  console.log("......CATEGORY", category)

  useEffect(() => {
    if (category !== undefined) {
      dbCreateAndUpdateCategory();
      setAnalytics_Key("")
      setCategoryDesc("")
      setBtnClr("")
      setHeaderBgClr("")
      setNavClr("")
      setTxtClr("")
      setCategoryKey("")
      setMinBuildNumber(0)
      setCategoryOrder(0)
      setSectionId("")
      setStatus("")
      setCategoryTitle("")
      setCategory(undefined)
    }
  }, [category])

  console.log("%%%%%%%%", analytics_Key, categoryDesc, btnClr, categoryKey, sectionId)

  const dbCreateAndUpdateCategory = async () => {
    console.log("**** JE PASSE PAR LA ???")
    let updatedCategory: Category = { ...category };
    console.log("**** UPDATED CATEGORY AND KEY", updatedCategory, updatedCategory.key)
    await setDBCategory({ key: updatedCategory.key, value: updatedCategory })
  }

  // ********** FONCTION VALIDER LE FORMULAIRE**********
  const validateNewCategoryForm = () => {
    setNewCategory(true)
  }

  // ********** PARTIE POUR LA SELECTION DE LA SECTION **********
  const onSectionSelection = (e: { value: string }) => {
    setSection(e)
    setSectionId(e.value)
  }
  // console.log("SECTIONID", sectionId)

  interface SectionInterface {
    value: string,
    name: string
  }

  const getSection = () => {
    const sectionArray: SectionInterface[] = [
      {
        value: "still",
        name: "Je me pose"
      },
      {
        value: "talk",
        name: "Je m'exprime"
      },
      {
        value: "grow",
        name: "Je grandis"
      },
      {
        value: "inspire",
        name: "Je m'inspire"
      },
      {
        value: "liliAtHome",
        name: "Lili à la maison"
      },
      {
        value: "liliAtSchool",
        name: "Lili à l'école"
      },
      {
        value: "onBoardingSteps",
        name: "Conseils d'utilisation de l'app"
      },
      {
        value: "superPowers",
        name: "Super pouvoir"
      },
      {
        value: "superPrograms",
        name: "Super programmes"
      }
    ];

    let sectionSelectionOptions: { value: string, label: string }[] = [];
    for (const aSection in sectionArray) {
      if (Object.prototype.hasOwnProperty.call(sectionArray, aSection)) {
        const element = sectionArray[aSection];
        sectionSelectionOptions.push({
          value: element.value,
          label: element.name + " - " + element.value
        })
      }
    }
    return (
      <Select
        value={section}
        onChange={(e) => onSectionSelection(e)}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont
          })
        }} options={sectionSelectionOptions}

      />
    )
  }

  // ********** PARTIE POUR LA SÉLECTION DE L'ANALYTICS KEY **********
  const onAnalyticsKeySelection = (e: { value: string }) => {
    setAnalyticsKey(e)
    setAnalytics_Key(e.value)
  }

  // console.log("ANALYTICS_KEY VALUE", analytics_Key)

  interface AnalyticsKeyInterface {
    value: string,
    name: string
  }
  const getAnalyticsKey = () => {
    const analyticsKeyArray: AnalyticsKeyInterface[] = [
      {
        value: "TIPS_PARENT",
        name: "Conseil aux parents"
      },
      {
        value: "BREATH",
        name: "Respirer"
      },
      {
        value: "PPE_CLASS",
        name: "Introduction à la phylosophie"
      },
      {
        value: "FOCUS",
        name: "Concentration"
      },
      {
        value: "DRAWS",
        name: "Dessins"
      },
      {
        value: "DISCOVERY",
        name: "Découverte"
      },
      {
        value: "LISTEN",
        name: "Écoute"
      },
      {
        value: "ORIGA",
        name: "Origamie"
      },
      {
        value: "CARDS",
        name: "Cartes"
      },
      {
        value: "IMPRO",
        name: "Improvisation"
      }
    ]

    let analyticsKeyOptions: { value: string, label: string }[] = [];
    for (const anAnalyticsKey in analyticsKeyArray) {
      if (Object.prototype.hasOwnProperty.call(analyticsKeyArray, anAnalyticsKey)) {
        const element = analyticsKeyArray[anAnalyticsKey];
        analyticsKeyOptions.push({
          value: element.value,
          label: element.name + " - " + element.value
        })
      }
    }
    return <Select
      value={analyticsKey}
      onChange={(e) => onAnalyticsKeySelection(e)}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: appTheme.pixelPerfect(30),
          borderRadius: appTheme.pixelPerfect(10),
          fontFamily: appTheme.primaryFont
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          fontFamily: appTheme.primaryFont
        })
      }} options={analyticsKeyOptions}
    />
  }


  // ********** PARTIE POUR LA DESCRIPTION DE LA CATGORIE (BODY QUI CONTIENT 2 LANGUES) **********

  const onSelectCategoryDescLang = (e: { value: string }) => {
    setCategoryDescLang(e)
  }

  const onChangeCategoryDesc = (e: { value: string }) => {
    setCategoryDesc(e)
  }

  interface languagesByKeyInterface {
    name: string,
    value: string
  }
  const languagesByKey: languagesByKeyInterface[] = [
    {
      name: "Français",
      value: "fr"
    },
    {
      name: "Anglais",
      value: "en"
    }
  ];
  const getCategoryDesc = () => {
    let categoryDescLangOptions: { label: string, value: string }[] = []
    for (const aLanguagesByKey in languagesByKey) {
      if (Object.prototype.hasOwnProperty.call(languagesByKey, aLanguagesByKey)) {
        const element = languagesByKey[aLanguagesByKey]
        categoryDescLangOptions.push({
          value: element.value,
          label: element.name + " - " + element.value
        })
      }
    }
    return (
      <View >
        <Select
          value={categoryDescLang}
          onChange={(e) => onSelectCategoryDescLang(e)}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: appTheme.pixelPerfect(30),
              borderRadius: appTheme.pixelPerfect(10),
              fontFamily: appTheme.primaryFont,

            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
              ...styles,
              fontFamily: appTheme.primaryFont
            })
          }} options={categoryDescLangOptions}
        />
        <TextInput value={categoryDesc} onChangeText={(e) => onChangeCategoryDesc(e)} style={[inputStyle, { height: appTheme.pixelPerfect(100), width: "100%" }]} />
      </View>

    )
  };

  // ********** PARTIE POUR LA SAISIE DU TITRE DE LA CATEGORY **********
  const onSelectCategoryTitle = (e: { value: string }) => {
    setCategoryTitleLang(e)
  };

  const onChangeCategoryTitle = (e: { value: string }) => {
    setCategoryTitle(e)
  }

  const getCategoryTitle = () => {
    let categoryTitleLangOptions: { label: string, value: string }[] = [];
    for (const aLangaugeByKey in languagesByKey) {
      if (Object.prototype.hasOwnProperty.call(languagesByKey, aLangaugeByKey)) {
        const element = languagesByKey[aLangaugeByKey]
        categoryTitleLangOptions.push({
          value: element.value,
          label: element.name + " - " + element.value
        })
      }
    };
    return (
      <View>
        <Select
          value={categoryTitleLang}
          onChange={(e) => onSelectCategoryTitle(e)}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: appTheme.pixelPerfect(30),
              borderRadius: appTheme.pixelPerfect(10),
              fontFamily: appTheme.primaryFont,

            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
              ...styles,
              fontFamily: appTheme.primaryFont,

            })
          }} options={categoryTitleLangOptions}
        />
        <TextInput onChangeText={(e) => onChangeCategoryTitle(e)} value={categoryTitle} style={[inputStyle, { height: appTheme.pixelPerfect(100), width: "100%" }]} />
      </View>
    )
  };


  // ********** PARTIE POUR LA SAISIE DE LA CLÉ PERMETTANT D'IDENTIFIÉ LA CATEGORIE **********
  const onChangeCategoryKey = (e) => {
    setCategoryKey(e)
  }
  const getCategoryKey = () => {
    return (
      <View>
        <TextInput value={categoryKey} onChangeText={(e) => onChangeCategoryKey(e)} style={[inputStyle, { width: "100%" }]} />
      </View>
    )
  };

  // ********** PARTIE POUR LA SAISIE DU ORDER "ORD" **********
  const onChangeCategoryOrder = (e) => {
    if (!isNaN(e) && !isNaN(parseInt(e))) {
      setCategoryOrder(parseInt(e))
    }
  }
  const getOrder = () => {
    return (
      <View >
        <TextInput onChangeText={(e) => onChangeCategoryOrder(e)} style={[inputStyle, { width: "100%" }]} />
      </View>
    )
  };

  // ********** PARTIE POUR LA SELECTION DU STATUS **********

  const onStatusSelection = (e: { value: string }) => {
    setCategoryStatus(e)
    setStatus(e.value)
  }
  interface StatusKeyInterface {
    value: string
    name: string
  }

  const statusKeyArray: StatusKeyInterface[] = [
    {
      value: "draft",
      name: "Brouillon"
    },
    {
      value: "published",
      name: "Publié"
    }
  ]
  const getStatus = () => {
    let statusSelectionOptions: { value: string, label: string }[] = [];
    for (const aStatus in statusKeyArray) {
      if (Object.prototype.hasOwnProperty.call(statusKeyArray, aStatus)) {
        const element = statusKeyArray[aStatus];
        statusSelectionOptions.push({
          value: element.value,
          label: element.name + " - " + element.value
        })
      }
    }
    return (
      <Select
        value={categoryStatus}
        onChange={(e) => onStatusSelection(e)}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont
          })
        }} options={statusSelectionOptions}

      />
    )
  };

  // ********** PARTIE POUR LA SAISIE DU MINIMUM BUILD NUMBER **********
  const onChangeCategoryMinBuildNumber = (text: string) => {
    //TO DO : Vérifier que text est composé de chiffres uniquement
    let isNumber: boolean = /^\d+$/.test(text);
    if ((isNumber === true) || (text.length === 0)) {
      setMinBuildNumber(parseInt(text));
      setMinBuildNumberError("");
    } else {
      setMinBuildNumberError("Le numéro de build doit être un nombre !");
    }
  }
  const getMinBuildNumber = () => {
    const errorLabelView: React.JSX.Element = minBuildNumberError.length > 0 ? <Text style={{ color: "#800000" }}>{minBuildNumberError}</Text> : <View />;
    const inputBorderColor: string = minBuildNumberError.length > 0 ? "#800000" : appTheme.grow;
    return (
      <View>
        <TextInput
          onChangeText={(text) => onChangeCategoryMinBuildNumber(text)}
          style={[inputStyle, { width: "100%", borderColor: inputBorderColor }]} />
        {errorLabelView}
      </View>
    )
  };

  // ********** PARTIE POUR LA DÉFINITION DE LA COULEUR **********

  const onChangeBtnColor = (e) => {
    setBtnClr(e)
  }
  const onChangeHeaderBgClr = (e) => {
    setHeaderBgClr(e)
  }

  const onChangeNavClr = (e) => {
    setNavClr(e)
  };

  const onChangeTxtClr = (e) => {
    setTxtClr(e)
  }


  const inspireBtnClr = "#FFC94E";
  const inspireHeaderBgClr = "#FFC94E";
  const inspireNavClr = "#FFF085";
  const inspireTxtClr = "#25364F";

  useEffect(() => {
    if (sectionId === "inspire") {
      setBtnClr(inspireBtnClr)
      setHeaderBgClr(inspireHeaderBgClr)
      setNavClr(inspireNavClr)
      setTxtClr(inspireTxtClr)
    } else {
      setBtnClr("")
      setHeaderBgClr("")
      setNavClr("")
      setTxtClr("")
    }
  }, [sectionId])


  const getClrForCategoryElement = () => {

    let elementClrInput = <View />
    if (sectionId === "inspire") {
      elementClrInput = <View style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

        <View>
          <Text>Couleur du btn FOR INSPIRE</Text>
          <TextInput
            value={inspireBtnClr}
            editable={false}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
        <View>
          <Text>Couleur du header-bg</Text>
          <TextInput
            value={inspireHeaderBgClr}
            editable={false}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
        <View>
          <Text>Couleur de la nav</Text>
          <TextInput
            value={inspireNavClr}
            editable={false}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
        <View>
          <Text>Couleur du txt</Text>
          <TextInput
            value={inspireTxtClr}
            editable={false}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
      </View>
    } else {
      elementClrInput = <View style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <Text>Couleur du btn !== "inspire"</Text>
          <TextInput
            value={btnClr}
            onChangeText={(e) => onChangeBtnColor(e)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
        <View>
          <Text>Couleur du header-bg</Text>
          <TextInput
            value={headerBgClr}
            onChangeText={(e) => onChangeHeaderBgClr(e)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
        <View>
          <Text>Couleur de la nav</Text>
          <TextInput
            value={navClr}
            onChangeText={(e) => onChangeNavClr(e)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
        <View>
          <Text>Couleur du txt</Text>
          <TextInput
            value={txtClr}
            onChangeText={(e) => onChangeTxtClr(e)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150) }]}
          />
        </View>
      </View>
    }
    return (
      elementClrInput
    )
  };

  // ********** BOUTON POUR FAIRE APPARAITRE LE FORMULAIRE DE CRÉATION D'UNE NOUVELLE CATEGORY **********
  const createNewCategoryButton = () => {
    return (
      <Button disabled={(section === undefined)}
        style={{ width: appTheme.pixelPerfect(300) }}
        title={"Créer une nouvelle categorie"}
        onPress={() => setShowNewCategoryForm(true)} />
    )
  };

  // BOUTON POUR ENREGISTRER L'ENREGISTREMENT DE LA NOUVELLE CATEGORY
  const recordButtonTitle: string = showNewCategoryForm === true ? "Enregistrer" : "Mettre à jour";



  const getContent = () => {
    if (viewDidAppear === false) {
      return <View onLayout={onViewWillAppear} style={{ width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={appTheme.grow} loading={true} />
      </View>
    }
    return (
      <View style={{ width: "100%" }}>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          {createNewCategoryButton()}
        </View>
        <Text style={labelStyle}>Choix de la section</Text>
        <Divider size={5} />
        {getSection()}


        {/* CE QUI SUIT N'APPARAIT PAS SI LA SECTION N'EST PAS DÉFINIE */}

        {showNewCategoryForm === true ? <View>
          <Divider size={10} />
          <Text style={labelStyle}>Analytics Key</Text>
          <Divider size={5} />
          {getAnalyticsKey()}

          <Divider size={10} />
          <Text style={labelStyle}>Description de la catégorie (body)</Text>
          <Divider size={5} />
          {getCategoryDesc()}

          <Divider size={10} />
          <Text style={labelStyle}>Titre de la catégorie</Text>
          <Divider size={5} />
          {getCategoryTitle()}

          <Divider size={10} />
          <Text style={labelStyle}>Clé de la catégorie</Text>
          <Divider size={5} />
          {getCategoryKey()}

          <Divider size={10} />
          <Text style={labelStyle}>Ordre de la catégorie</Text>
          <Divider size={5} />
          {getOrder()}

          <Divider size={10} />
          <Text style={labelStyle}>Status de la catégorie</Text>
          <Divider size={5} />
          {getStatus()}

          <Divider size={10} />
          <Text style={labelStyle}>Minimum Build Number de la catégorie</Text>
          <Divider size={5} />
          {getMinBuildNumber()}

          <Divider size={10} />
          <Text style={labelStyle}>Couleurs des éléments</Text>
          <Divider size={5} />
          {getClrForCategoryElement()}

          <Divider size={10} />
          <Button style={{ width: appTheme.pixelPerfect(300) }} title={recordButtonTitle} onPress={() => validateNewCategoryForm()} />
        </View> : <View />}
        <Divider />
      </View>
    )
  }


  return (
    <View style={{ padding: appTheme.pixelPerfect(20) }}>
      <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={() => goBack()}>
          <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
            Dashboard
          </Text>
        </TouchableOpacity>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          {">"}
        </Text>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          Catégories
        </Text>
      </View>
      {getContent()}
    </View>
  )

};

export default LHQCategoriesManagement;