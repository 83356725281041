// Fichier ayant pour but de ne pas utiliser Firebase sur les instances AppWrite

import User from "../../shared/data/user/User";
import Activity from "../../shared/models/Activity";

/**
 * SCHOOL
 */
export const getSchoolId = async (data: { name: string, address: string, zipcode: string, city: string, country: string }) => {};

export const createSchool = async (data: { name: string, address: string, zipcode: string, city: string, country: string }) => {}

/**
 * GIFT
 */

export const createGiftForUser = async (data: { gift: any }) => {}

/**
 * 
 * USER
 */
export const getUserWithId = async (data: { user_id: string }) => {
    
}

export const getUserWithEmail = async (data: { user_email: string }) => {}

export const addDataToUser = async (data: { key: string, value: any, user_id: string }) => {

}

export const addUserWithData = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string, opt_in: boolean, sub_id?: string, duration_in_month?: number, addCode?: boolean }) => {

}

export const addPreSubToUser = async (data: { user_id: string, sub_id: string, duration_in_month: number }) => {

}

export const addFilterToUser = async (data: { user_id: string, filters: string[] }) => {

}

export const addSubToUser = async (data: { user_id: string, sub_id: string, duration_in_month?: number, expiration_date?: number }) => {

}

// User deletion
export const deleteUser = async (data: { user_id: string }) => {

}

export const createTeacherCode = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string }) => {

}

export function getCollection({ aCollection, query }) {

}

export const addDocument = async (data: { aCollection: string, content: any }) => {

}

export function setDocument(data: { aCollection: string, key: string, content: any }) {

}

// GAMIFICATION
export const getUserActions = async (data: { user_id: string }) => {

}

// LISTENERS
export const revokeDBListeners = () => {

}
export const launchUserListener = async (data: { user_id: string }) => {

}

export const updateUserData = async (data: { user_data: User }) => {

}

// Déblocage de badges
export const unlockedTheBadge = (data: { badgeId: string, user_id: string }) => {

}

//Nouvelle Gamification
export const onActivityCompleted = async (data: { activityKey: string, iteration: number, categoryKey: string, sectionKey: string, detail: string, menu: string }) => {
   
}

export const updateActions = (data: { pts: number, last_connexion_date: number, days_in_a_row: number }) => {
    
}

export const userActionsListener = async (data: { user_id: string }) => {
    
}

export const categoriesListener = async (data: { ts: number }) => {

}

export const activitiesListener = async (data: { ts: number }) => {

}

export function getUserGiftCards(data: { user_email: string }) {
    
}

export async function redeemedCardInDb(data: { card: any, user: User }) {

}

export async function transfertCardToNewUser(data: { card: any, user_email: string }) {

}