// Fichier ayant pour but de ne pas utiliser Firebase sur les instances AppWrite
import { AppTarget, getAppTarget } from "../utils/Navigation/Host";
import { getCurrentUser } from "./AppWrite/AppWriteDatabase";

// Get current user
export const dbCurrentUser = async () => {
    const appTarget: AppTarget = getAppTarget();
    let currentAccount = await getCurrentUser();
    if ((currentAccount !== null) && (currentAccount !== undefined)) {
        if ((currentAccount.prefs !== undefined) && (currentAccount.prefs.app !== undefined)) {
            if (currentAccount.prefs.app !== appTarget) {
                return null;
            } else {
                return currentAccount;
            }
        }
    }
}

// User SignUp
export async function dbCreateUserWithEmailAndPassword(data: { email: string, password: string }) {

}

// User SignIn
export async function dbSignInWithEmailAndPassword({ email, password }) {

}

// User reset password
export function revokePassword(credential: { email: string }) {

}

// User Sign out
export function dbSignOut() {

}